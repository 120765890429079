import { SelectOption } from "components/atoms/Select";
import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { config } from "config";
import { TagState } from ".";

const default_page_size = config.PAGE_SIZE;
const initialState: TagState = {
  count: 0,
  refresh: 0,
  tags: [],
  tag: null,
  loading: false,
  tagOptions: [],
  current_filters: {},
  sorting: {},
  refreshLoader: false,
  filters: {
    page: 1,
    page_size: default_page_size,
  },
};

export const tagSlice = createSlice({
  name: "tag",
  initialState,
  reducers: {
    clear: (state) => {
      state.tagOptions = [];
      state.tags = [];
    },
    setLoading: (state, action: PayloadAction<boolean>) => {
      state.loading = action.payload;
    },
    addTag: (state, action) => {
      state.tags.unshift(action.payload);
      const makeObject = {
        value: action.payload?._id,
        label: action.payload?.value,
      };
      state.tagOptions.unshift(makeObject);
    },
    updateTag: (state, action) => {
      const { id, tag } = action.payload;
      state.tags.every(({ _id }, i) => {
        if (id === _id) {
          state.tags[i] = tag;
          return false;
        }
        return true;
      });
    },
    setTag: (state, action) => {
      state.tag = action.payload;
    },
    setTags: (state, action: PayloadAction<{ tags: any[]; count: number }>) => {
      const { count, tags } = action.payload;
      let options: SelectOption[] = [];

      tags?.forEach(({ _id, firstName, lastName }: any) => {
        const option = { value: _id, label: `${firstName} ${lastName || ""}` };
        options.push(option);
      });

      state.count = count;
      state.tags = tags;
      state.refreshLoader = false;
    },

    setPage: (state, action: PayloadAction<number>) => {
      state.refresh += 1;
      state.refreshLoader = true;
      state.filters.page = action.payload;
    },
    refresh: (state) => {
      state.refresh += 1;
      state.refreshLoader = true;
    },
    resetPage: (state) => {
      state.filters.page = 1;
    },

    setTagOptions: (state, action) => {
      state.tagOptions = action.payload;
    },

    setFilters: (state, action) => {
      state.filters = action.payload;
      state.refreshLoader = true;
    },
    setSorting: (state, action) => {
      state.sorting = action.payload;
      state.refreshLoader = true;
    },
    setCurrentFilters: (state, action) => {
      state.current_filters = action.payload;
    },
    resetFilters: (state) => {
      state.refresh += 1;
      state.refreshLoader = true;
      state.filters = initialState.filters;
      state.current_filters = initialState.current_filters;
      state.sorting = initialState.sorting;
    },
    deleteTag: (state, action) => {
      const id = action.payload;
      state.tags.every(({ _id }, i) => {
        if (id === _id) {
          state.count -= 1;
          state.tags.splice(i, 1);
          return false;
        }
        return true;
      });
    },
  },
});

const tagReducer = tagSlice.reducer;
export const tagActions = tagSlice.actions;
export default tagReducer;
