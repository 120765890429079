import { ProtocolState } from ".";
import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { config } from "config";

const default_page_size = config.PAGE_SIZE;
const initialState: ProtocolState = {
  count: 0,
  refresh: 0,
  protocols: [],
  protocol: null,
  loading: true,
  protocolOptions: [],
  current_filters: {},
  sorting: {},

  refreshLoader: false,
  filters: { page: 1, page_size: default_page_size },
};

export const protocolSlice = createSlice({
  name: "protocol",
  initialState,
  reducers: {
    clear: (state) => {
      state.protocolOptions = [];
      state.protocols = [];
    },
    setLoading: (state, action: PayloadAction<boolean>) => {
      state.loading = action.payload;
    },
    addProtocol: (state, action) => {
      state.protocols.unshift(action.payload);
    },

    updateProtocol: (state, action) => {
      const { id, protocol } = action.payload;
      state.protocols.every(({ _id }, i) => {
        if (id === _id) {
          state.protocols[i] = protocol;
          return false;
        }
        return true;
      });
    },
    setProtocolOptions: (state, action) => {
      state.protocolOptions = action.payload;
    },
    setProtocol: (state, action) => {
      state.protocol = action.payload;
    },
    setProtocols: (
      state,
      action: PayloadAction<{ protocols: any[]; count: number }>
    ) => {
      const { count, protocols } = action.payload;

      state.count = count;
      state.protocols = protocols;
      state.refreshLoader = false;
      // state.protocolOptions = options;
    },

    setPage: (state, action: PayloadAction<number>) => {
      state.refresh += 1;
      state.refreshLoader = true;
      state.filters.page = action.payload;
    },
    refresh: (state) => {
      state.refresh += 1;
      state.refreshLoader = true;
    },
    resetPage: (state) => {
      state.filters.page = 1;
    },

    setFilters: (state, action) => {
      state.filters = action.payload;
      state.refreshLoader = true;
    },
    setSorting: (state, action) => {
      state.sorting = action.payload;
      state.refreshLoader = true;
    },
    setCurrentFilters: (state, action) => {
      state.current_filters = action.payload;
    },
    resetFilters: (state) => {
      state.refresh += 1;
      state.refreshLoader = true;
      state.filters = initialState.filters;
      state.current_filters = initialState.current_filters;
      state.sorting = initialState.sorting;
    },
    deleteProtocol: (state, action) => {
      const id = action.payload;
      state.protocols.every(({ _id }, i) => {
        if (id === _id) {
          state.count -= 1;
          state.protocols.splice(i, 1);
          return false;
        }
        return true;
      });
    },
  },
});

const protocolReducer = protocolSlice.reducer;

export const protocolActions = protocolSlice.actions;
export default protocolReducer;
