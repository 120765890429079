import type React from "react";
import { useState, useCallback } from "react";
import {
  IconButton,
  Popper,
  Paper,
  List,
  ListItem,
  ListItemText,
  ListItemAvatar,
  Typography,
  Box,
  ClickAwayListener,
  Button,
  styled,
  alpha,
  Badge,
  Tooltip,
  CircularProgress,
} from "@mui/material";
import { NotificationsOutlined, CheckCircleOutline } from "@mui/icons-material";
import { SiHandshakeProtocol } from "react-icons/si";
import { useAppSelector } from "redux/hooks";
import NotificationHistoryService from "services/notificationHistory.service";
import {
  differenceInMinutes,
  differenceInHours,
  differenceInDays,
} from "date-fns";
import { useNavigate } from "react-router-dom";
import useEffectOnce from "hooks/useEffectOnce";
import NotificationItem from "./NotificationItem";
import { useTranslation } from "react-i18next";

function formatTimeDistance(date: any) {
  const now = new Date();
  const diffMinutes = differenceInMinutes(now, date);

  if (diffMinutes < 60) {
    return `${diffMinutes}m`; // Less than 60 minutes -> show in minutes
  }

  const diffHours = differenceInHours(now, date);

  if (diffHours < 24) {
    return `${diffHours}h`; // Less than 24 hours -> show in hours
  }

  const diffDays = differenceInDays(now, date);
  return `${diffDays}d`; // 1 day or more -> show in days
}
const icons: Record<string, React.ReactNode> = {
  protocol: <SiHandshakeProtocol />,
};

const StyledPaper = styled(Paper)(({ theme }) => ({
  width: 400,

  [theme.breakpoints.down("sm")]: {
    width: "100%",
  },
  maxHeight: 480,
  overflow: "hidden",
  borderRadius: 4,
  boxShadow: "0 4px 20px rgba(0, 0, 0, 0.08)",
  border: "1px solid rgba(0, 0, 0, 0.04)",
}));

const StyledListItem = styled(ListItem)<{ isread: string }>(
  ({ theme, isread }) => {
    return {
      // my: 1,

      paddingLeft: "6px",
      position: "relative",
      transition: "background-color 0.3s",
      backgroundColor:
        isread == "true"
          ? alpha(theme.palette.primary.main, 0.08)
          : "transparent",
      // "&:hover": {
      //   backgroundColor:
      //     isread == "false"
      //       ? alpha(theme.palette.primary.main, 0.12)
      //       : alpha(theme.palette.primary.main, 0.08),
      // },
    };
  }
);

const NotificationTitle = styled(Typography)(({ theme }) => ({
  fontSize: "0.9375rem",
  fontWeight: 500,
  color: theme.palette.text.primary,
  "& .name": {
    color: theme.palette.primary.main,
    fontWeight: 600,
  },
}));

const NotificationTime = styled(Typography)(({ theme }) => ({
  fontSize: "0.75rem",
  color: theme.palette.text.secondary,
  // marginTop: 4,
}));

const ViewAllButton = styled(Button)(({ theme }) => ({
  width: "100%",
  color: theme.palette.primary.main,
  fontWeight: 500,
  textTransform: "none",
  fontSize: "0.875rem",
  padding: "12px 20px ",
  justifyContent: "flex-start",
  borderTop: `1px solid ${theme.palette.divider}`,
  transition: "background-color 0.3s",
  "&:hover": {
    backgroundColor: alpha(theme.palette.primary.main, 0.04),
  },
}));

const NotificationPanel: React.FC = () => {
  const { t } = useTranslation();
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const {
    loading,
    notificationHistoriesDefault = [],
    defaultCount,
  } = useAppSelector((state) => state.notificationHistory);
  const { user } = useAppSelector((state) => state.auth);
  const [isMarkingAsRead, setIsMarkingAsRead] = useState(false);
  const navigate = useNavigate();

  const handleClick = useCallback(
    (event: React.MouseEvent<HTMLElement>) => {
      setAnchorEl(anchorEl ? null : event.currentTarget);
    },
    [anchorEl]
  );

  useEffectOnce(() => {
    NotificationHistoryService.getAllNotificationHistories(
      {
        page_size: 10,
        page: 1,
        sorting: { createdAt: -1 },
        defaultRead: false,
      },
      "default"
    );
  });

  const handleClickAway = useCallback(() => {
    setAnchorEl(null);
  }, []);
  const ids = notificationHistoriesDefault.map(
    (notification) => notification._id
  );

  const handleMarkAllAsRead = useCallback(async () => {
    setIsMarkingAsRead(true);
    try {
      await NotificationHistoryService.bulkUpdate({ ids, action: "read" });
      NotificationHistoryService.getAllNotificationHistories({
        page_size: 10,
        page: 1,
        sorting: { createdAt: -1 },
        defaultRead: false,
      });
    } catch (error) {
      console.error("Failed to mark all notifications as read:", error);
    } finally {
      setIsMarkingAsRead(false);
    }
  }, [ids.length]);

  const open = Boolean(anchorEl);
  const id = open ? "notification-popper" : undefined;

  const navigateNotification = (notification: any) => {
    console.log("notification", notification);
    if (notification.type === "protocol") {
      navigate("/protocol-details/" + notification.typeId);
    } else if (notification.type === "task") {
      navigate("/task-details/" + notification.typeId);
    }
    if (notification.type === "message") {
      navigate("/chat?chatId=" + notification.typeId);
    }
    if (!notification?.isRead) {
      NotificationHistoryService.markAsRead({
        id: notification._id,
        action: "read",
      });
    }
    handleClickAway();
  };

  return (
    <div>
      <Tooltip title={t("notifications")}>
        <IconButton
          onClick={handleClick}
          // size="large"
          sx={{
            color: open ? "primary.main" : "inherit",
            "&:hover": {
              backgroundColor: (theme) =>
                alpha(theme.palette.primary.main, 0.08),
            },
          }}
        >
          <Badge badgeContent={defaultCount} color="error">
            <NotificationsOutlined />
          </Badge>
        </IconButton>
      </Tooltip>
      <Popper
        id={id}
        open={open}
        anchorEl={anchorEl}
        placement="bottom-end"
        sx={{
          zIndex: 99999,
          minWidth: 400,
          "@media (max-width: 600px)": {
            minWidth: "100%",
            width: "100%",
          },
        }}
        modifiers={[
          {
            name: "offset",
            options: {
              offset: [0, 8],
            },
          },
        ]}
      >
        <ClickAwayListener onClickAway={handleClickAway}>
          <StyledPaper elevation={0}>
            <Box
              sx={{
                p: "16px 20px",
                borderBottom: (theme) => `1px solid ${theme.palette.divider}`,
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
              }}
            >
              <Typography
                variant="h6"
                sx={{ fontSize: "1rem", fontWeight: 600 }}
              >
                {t("Notifications")}
              </Typography>
              <Tooltip title="Mark all as read">
                <IconButton
                  onClick={handleMarkAllAsRead}
                  disabled={isMarkingAsRead || defaultCount === 0}
                  size="small"
                >
                  {isMarkingAsRead ? (
                    <CircularProgress size={20} />
                  ) : (
                    <CheckCircleOutline fontSize="small" />
                  )}
                </IconButton>
              </Tooltip>
            </Box>
            {loading ? (
              <Box sx={{ display: "flex", justifyContent: "center", p: 3 }}>
                <CircularProgress />
              </Box>
            ) : notificationHistoriesDefault.length > 0 ? (
              <List
                sx={{ py: 0, my: "8px", maxHeight: 360, overflowY: "auto" }}
              >
                {notificationHistoriesDefault.map((notification, index) => (
                  <NotificationItem notification={notification} key={index}>
                    <div
                      key={notification._id}
                      style={{
                        // marginBottom: 4,
                        marginBottom: 4,
                        padding: " 0px 12px",
                        width: "100%",
                        cursor: "pointer",
                        maxWidth: "100%",
                      }}
                    >
                      <StyledListItem
                        isread={(!notification.isRead).toString()}
                        onClick={() => navigateNotification(notification)}
                      >
                        <>
                          <ListItemAvatar sx={{ minWidth: 45 }}>
                            <div
                              style={{
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                                width: 40,
                                height: 40,
                                borderRadius: "50%",
                                backgroundColor: "rgba(0, 0, 0, 0.04)",
                              }}
                            >
                              {icons[
                                notification.type as keyof typeof icons
                              ] || <NotificationsOutlined />}
                            </div>
                          </ListItemAvatar>

                          <ListItemText
                            disableTypography
                            sx={{ m: 0 }}
                            primary={
                              <NotificationTitle>
                                <span className="name">
                                  {notification.title}
                                </span>
                                {notification.actionRequired &&
                                  !notification?.isRead && (
                                    <Button
                                      type="button"
                                      variant="contained"
                                      color="primary"
                                      size="small"
                                      sx={{
                                        fontSize: "0.75rem",
                                        padding: "2px 8px",
                                        marginLeft: 1,
                                      }}
                                      onClick={() => {
                                        if (notification.url) {
                                          if (!notification?.isRead) {
                                            NotificationHistoryService.markAsRead(
                                              {
                                                id: notification._id,
                                                action: "read",
                                              }
                                            );
                                          }
                                          window.open(
                                            notification.url,
                                            "_blank"
                                          );

                                          handleClickAway();
                                        }
                                      }}
                                    >
                                      {t("actionRequired")}
                                    </Button>
                                  )}
                                {/* <br />
                          <span style={{ fontSize: 12 }}>
                            {notification.description}
                          </span> */}
                              </NotificationTitle>
                            }
                            secondary={
                              <p
                                style={{
                                  fontSize: 12,

                                  width: "90%",
                                  margin: 0,
                                  whiteSpace: "nowrap",
                                  overflow: "hidden",
                                  textOverflow: "ellipsis",
                                }}
                              >
                                <>{notification.description}</>
                              </p>
                            }
                          />
                          <div>
                            <small>
                              {formatTimeDistance(
                                new Date(notification.createdAt)
                              )}
                            </small>
                          </div>
                        </>
                      </StyledListItem>
                    </div>
                  </NotificationItem>
                ))}
              </List>
            ) : (
              <Box sx={{ p: 3, textAlign: "center" }}>
                <Typography variant="body2" color="text.secondary">
                  {t("No new notifications")}
                </Typography>
              </Box>
            )}
            <ViewAllButton
              sx={{ paddingBottom: "20px" }}
              onClick={() => {
                navigate(
                  user?.role === "driver"
                    ? "/notification-history"
                    : "/notifications"
                );
                handleClickAway();
              }}
            >
              {t("seeAllNotifications")}
            </ViewAllButton>
          </StyledPaper>
        </ClickAwayListener>
      </Popper>
    </div>
  );
};

export default NotificationPanel;
