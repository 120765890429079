import { config } from "config";
import { NotificationHistoryState } from ".";
import { createSlice, PayloadAction } from "@reduxjs/toolkit";

const default_page_size = config.PAGE_SIZE;

const initialState: NotificationHistoryState = {
  count: 0,
  defaultCount: 0,
  loading: true,
  notificationHistories: [],
  notificationHistoriesDefault: [],
  notificationHistory: null,
  refresh: 0,
  refreshLoader: false,
  current_filters: {},
  sorting: {},
  filters: { page: 1, page_size: default_page_size },
};

export const notificationHistorySlice = createSlice({
  name: "notificationHistory",
  initialState,
  reducers: {
    setLoading: (state, action: PayloadAction<boolean>) => {
      state.loading = action.payload;
    },
    addNotificationHistory: (state, action) => {
      state.notificationHistories.unshift(action.payload);
      state.notificationHistoriesDefault.unshift(action.payload);
      state.defaultCount += 1;
      state.count += 1;
    },
    updateNotificationHistory: (state, action) => {
      const { id, notificationHistory } = action.payload;
      state.notificationHistories.every(({ _id }, i) => {
        if (id === _id) {
          state.notificationHistories[i] = notificationHistory;
          return false;
        }
        return true;
      });
      state.notificationHistoriesDefault.every(({ _id }, i) => {
        if (id === _id) {
          state.notificationHistoriesDefault[i] = notificationHistory;
          return false;
        }
        return true;
      });
    },

    deleteNotificationHistory: (state, action) => {
      const id = action.payload;
      state.notificationHistories.every(({ _id }, i) => {
        if (id === _id) {
          state.count -= 1;
          state.notificationHistories.splice(i, 1);
          return false;
        }
        return true;
      });
      state.notificationHistoriesDefault.every(({ _id }, i) => {
        if (id === _id) {
          state.count -= 1;
          state.notificationHistoriesDefault.splice(i, 1);
          return false;
        }
        return true;
      });
    },
    setNotificationHistory: (state, action) => {
      state.notificationHistory = action.payload;
    },
    setNotificationHistoryHistories: (
      state,
      action: PayloadAction<{
        notificationHistories: any[];
        count: number;
        defaultCount: number;
        type: "default" | "main";
      }>
    ) => {
      const { count, notificationHistories, defaultCount, type } =
        action.payload;
      state.notificationHistories = notificationHistories;
      state.count = count;
      state.refreshLoader = false;

      if (type == "default") {
        state.notificationHistoriesDefault = notificationHistories;
        state.defaultCount = defaultCount;
      }
    },
    setPage: (state, action: PayloadAction<number>) => {
      state.refresh += 1;
      state.refreshLoader = true;
      state.filters.page = action.payload;
    },
    refresh: (state) => {
      state.refresh += 1;
      state.refreshLoader = true;
    },
    resetPage: (state) => {
      state.filters.page = 1;
    },
    setFilters: (state, action) => {
      state.filters = action.payload;
      state.refreshLoader = true;
    },
    setSorting: (state, action) => {
      state.sorting = action.payload;
      state.refreshLoader = true;
    },
    setCurrentFilters: (state, action) => {
      state.current_filters = action.payload;
    },
    resetFilters: (state) => {
      state.refresh += 1;
      state.refreshLoader = true;
      state.filters = initialState.filters;
      state.current_filters = initialState.current_filters;
      state.sorting = initialState.sorting;
    },
  },
});

const notificationHistoryReducer = notificationHistorySlice.reducer;

export const notificationHistoryActions = notificationHistorySlice.actions;
export default notificationHistoryReducer;
