import { ExpenseState } from ".";
import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { config } from "config";

const default_page_size = config.PAGE_SIZE;
const initialState: ExpenseState = {
  count: 0,
  refresh: 0,
  totalExpense: 0,
  expenses: [],
  expense: null,
  loading: true,
  expenseOptions: [],
  current_filters: {},
  sorting: [],
  refreshLoader: false,
  pagination: { pageIndex: 0, pageSize: 20 },
  filters: [],
};

export const expenseSlice = createSlice({
  name: "expense",
  initialState,
  reducers: {
    clear: (state) => {
      state.expenseOptions = [];
      state.expenses = [];
    },
    setLoading: (state, action: PayloadAction<boolean>) => {
      state.loading = action.payload;
    },
    addExpense: (state, action) => {
      state.expenses.unshift(action.payload);
    },

    updateExpense: (state, action) => {
      const { id, expense } = action.payload;
      state.expenses.every(({ _id }, i) => {
        if (id === _id) {
          state.expenses[i] = expense;
          return false;
        }
        return true;
      });
    },
    setExpense: (state, action) => {
      state.expense = action.payload;
    },
    setExpenses: (
      state,
      action: PayloadAction<{
        expenses: any[];
        count: number;
        totalExpense: number;
      }>
    ) => {
      const { count, expenses, totalExpense } = action.payload;
      state.totalExpense = totalExpense;
      state.count = count;
      state.expenses = expenses;
      state.refreshLoader = false;
      // state.expenseOptions = options;
    },

    setPage: (state, action: PayloadAction<number>) => {
      state.refresh += 1;
      state.refreshLoader = true;
      state.filters.page = action.payload;
    },
    setPagination: (state, action: PayloadAction<any>) => {
      state.pagination = action.payload;
    },

    refresh: (state) => {
      state.refresh += 1;
      state.refreshLoader = true;
    },
    resetPage: (state) => {
      state.filters.page = 1;
    },

    setFilters: (state, action) => {
      state.filters = action.payload;
      state.refreshLoader = true;
    },
    setSorting: (state, action) => {
      state.sorting = action.payload;
      state.refreshLoader = true;
    },
    setCurrentFilters: (state, action) => {
      state.current_filters = action.payload;
    },
    resetFilters: (state) => {
      state.refresh += 1;
      state.refreshLoader = true;
      state.filters = initialState.filters;
      state.current_filters = initialState.current_filters;
      state.sorting = initialState.sorting;
    },
    deleteExpense: (state, action) => {
      const id = action.payload;
      state.expenses.every(({ _id }, i) => {
        if (id === _id) {
          state.count -= 1;
          state.expenses.splice(i, 1);
          return false;
        }
        return true;
      });
    },
  },
});

const expenseReducer = expenseSlice.reducer;

export const expenseActions = expenseSlice.actions;
export default expenseReducer;
