import { Stack } from "@mui/material";
import BasicMenu from "components/atoms/BasicMenu";
import { useTranslation } from "react-i18next";
import Flag from "react-world-flags";
import { useGetLanguages } from "store/useSimplelocalize";

const Localization = () => {
  const { data: languages = [], isLoading, error } = useGetLanguages();
  const { i18n } = useTranslation();

  if (isLoading) {
    return <div>Loading languages...</div>;
  }

  if (error) {
    return <div>Error: {error.message}</div>;
  }

  const countryCodeMap: { [key: string]: string } = {
    en: "GB",
    de: "DE",
    tr: "TR",
    ar: "SA",
  };

  return (
    <BasicMenu
      variant="contained"
      sx={{
        padding: "10px 12px",
        display: "flex",
        alignItems: "center",
        gap: "8px",
        "&:hover": { color: "white" },
        "@media (max-width: 600px)": {
          width: "80px",
        },
      }}
      list={languages.map((language) => ({
        text: (
          <Stack
            direction="row"
            spacing={1}
            alignItems="center"
            sx={{
              minWidth: "120px",
            }}
          >
            <Flag
              code={
                countryCodeMap[language.key.toLocaleLowerCase()] ||
                language.key.toUpperCase()
              }
              height="16"
              width={24}
            />
            <span>{language.name}</span>
          </Stack>
        ),
        onClick: () => i18n.changeLanguage(language.key),
      }))}
    >
      <Stack direction="row" spacing={1} alignItems="center">
        <Flag
          code={
            countryCodeMap[i18n.language?.toLocaleLowerCase()] ||
            i18n.language?.toUpperCase()
          }
          height="16"
        />
        <span>{i18n.language?.toUpperCase()}</span>
      </Stack>
    </BasicMenu>
  );
};

export default Localization;
