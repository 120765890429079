import { config } from "config";
import io from "socket.io-client";
import { log } from "../utils/logger.util";
import LocationService from "./location.service";
import NotificationHistoryService from "./notificationHistory.service";
import Notification from "assets/audio/notification.wav";
import ChatService from "./chat.service";
type EMIT_TYPES =
  | "join_room"
  | "leave_room"
  | "send_message"
  | "receive_message"
  | "chat_typing"
  | "mark_as_read"
  | "mark_as_delivered";
const audio = new Audio(Notification);

let socket: any;
let typingCallback: ((data: any) => void) | null = null;

const SocketService = {
  connect: ({
    _id,
    firstName,
    lastName,
    company,
    role,
    notificationSetting,
  }: any) => {
    let data = JSON.stringify({
      _id: _id,
      role: role,
      name: `${firstName} ${lastName}`,
      company: company?._id || company,
    });

    socket = io(config.SOCKET_URL, {
      query: { data },
      auth: { token: localStorage.getItem("token")?.slice(1, -1) },
    });
    log("socket init");

    socket.on("connect", () => {
      log("socket connected", socket.id);
    });

    socket.on("disconnect", (reason: any) => {
      log("socket disconnected", reason);
    });

    socket.on("connect_error", (e: any) => {
      log("socket connect_error", e.message);
      // socket.connect();
    });

    socket.onAny((event: any, ...args: any) => {
      // log("socket event", { event, args });
    });

    socket.on("update_message", (data: any) => {
      if (data) {
        ChatService.updateMessage(data);
      }
    });

    socket.on("notification", (data: any) => {
      if (data) {
        if (notificationSetting?.notificationSound) {
          try {
            audio.play();
          } catch (error) {
            console.log("error", error);
          }
        }
        console.log("data", data);

        NotificationHistoryService.addNotificationHistory(data);
      }
    });

    socket.on("LOCATION_UPDATE_ALL", (data: any) => {
      if (data) {
        LocationService.updateDriverLocation(data);
      }
    });

    socket.on("user_status", (data: any) => {
      if (data) {
        ChatService.updateChatUser(data);
      }
    });
    //chat message
    socket.on("receive_message", (data: any) => {
      console.log("receive_message", data);
      if (data) {
        ChatService.addMessage(data);
      }
    });
    socket.on("send_message", (data: any) => {
      console.log("send_message", data);
      if (data) {
        ChatService.addMessage(data);
      }
    });

    socket.on("chat_typing", (data: any) => {
      if (typingCallback) typingCallback(data); // Invoke callback
    });
  },

  emit(event: EMIT_TYPES, data: any) {
    if (socket) {
      socket.emit(event, JSON.stringify(data));
    }
  },

  setTypingCallback(callback: (data: any) => void) {
    typingCallback = callback; // Set the typing callback
  },
  disconnect: () => {
    if (!socket) return;

    socket.disconnect();
  },
};

export default SocketService;
