import { MessageState } from ".";
import { config } from "config";

import { createSlice, PayloadAction } from "@reduxjs/toolkit";
const default_page_size = config.PAGE_SIZE;

const initialState: MessageState = {
  messages: [],
  message: null,
  loading: true,
  filters: { page: 1, page_size: 10 },
  count: 0,
  chat: null,
};

export const messageSlice = createSlice({
  name: "message",
  initialState,
  reducers: {
    setLoading: (state, action: PayloadAction<boolean>) => {
      state.loading = action.payload;
    },

    setMessages: (state, action) => {
      state.messages = action.payload;
    },
    appendMessages: (state, action) => {
      state.messages = [...state.messages, ...action.payload]; // Append new messages at the top
    },
    setCount: (state, action) => {
      state.count = action.payload;
    },

    addMessage: (state, action) => {
      const message = action.payload;
      // state.messages.unshift(message);

      if (state.chat && state.chat?._id === message.chat) {
        state.messages.unshift(message);
      }
    },
    updateMessage: (state, action) => {
      const message = action.payload;
      const index = state.messages.findIndex((m) => m._id === message._id);
      if (index !== -1) {
        state.messages[index] = message;
      }
    },
    setMessage: (state, action) => {
      state.message = action.payload;
    },
    setChat: (state, action) => {
      state.chat = action.payload;
    },

    setFilters: (state, action) => {
      state.filters = action.payload;
    },
    reset: (state) => {
      state.messages = [];
      state.message = null;
      state.loading = true;
      state.filters = { page: 1, page_size: 10 };
      state.count = 0;
    },
  },
});

const messageReducer = messageSlice.reducer;

export const messageActions = messageSlice.actions;
export default messageReducer;
