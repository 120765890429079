import { locationActions } from "redux/slices/location";
import { getAppDispatch } from "utils/dispatch.util";
import http from "./http.service";
import Promisable from "./promisable.service";
const url = "/admin/user";
const LocationService = {
  updateDriverLocation: async (data: any) => {
    const dispatch = getAppDispatch();
    dispatch?.(locationActions.setDriverLocation(data));
    dispatch?.(locationActions.setVehicleLocation(data));
  },
  getDriverLocation: async (data: any) => {
    const dispatch = getAppDispatch();
    dispatch?.(locationActions.setLoading(true));
    dispatch?.(locationActions.setEmptyDrivers([]));

    http.setJWT();

    const [success, error]: any = await Promisable.asPromise(
      http.post(`${url}/getDriversLocation`, data)
    );

    if (success) {
      const { users } = success.data.data;
      dispatch?.(locationActions.setDriverLocation(users || []));
    }

    dispatch?.(locationActions.setLoading(false));
    return [success, error];
  },
};
export default LocationService;
