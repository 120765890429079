import "./instrument";

import { createRoot } from "react-dom/client";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import ErrorBoundary from "components/atoms/ErrorBoundary";
import InitializationService from "services/initialization.service";
import i18n from "./i18n";
import "./i18n";

import { I18nextProvider } from "react-i18next";
import "moment/locale/es";
import "moment/locale/de";
import * as Sentry from "@sentry/react";
import React from "react";

import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
InitializationService.init();
const container: any = document.getElementById("root");

const root = createRoot(container);
// Create a client
export const queryClient = new QueryClient();
root.render(
  <Sentry.ErrorBoundary
    fallback={({ error, componentStack, resetError }) => (
      <React.Fragment>
        <div
          style={{
            padding: "20px",
            maxWidth: "600px",
            margin: "40px auto",
            backgroundColor: "#fff",
            borderRadius: "8px",
            boxShadow: "0 2px 4px rgba(0,0,0,0.1)",
          }}
        >
          <h2
            style={{
              color: "#e53e3e",
              marginBottom: "16px",
            }}
          >
            Oops! Something went wrong
          </h2>

          <div
            style={{
              backgroundColor: "#fff5f5",
              padding: "12px",
              borderRadius: "4px",
              marginBottom: "12px",
              color: "#c53030",
              fontFamily: "monospace",
            }}
          >
            {(error as any).toString()}
          </div>

          <div
            style={{
              backgroundColor: "#f7fafc",
              padding: "12px",
              borderRadius: "4px",
              marginBottom: "20px",
              color: "#4a5568",
              fontFamily: "monospace",
              fontSize: "14px",
            }}
          >
            {componentStack}
          </div>

          <button
            onClick={() => {
              resetError();
            }}
            style={{
              backgroundColor: "#3182ce",
              color: "white",
              padding: "8px 16px",
              border: "none",
              borderRadius: "4px",
              cursor: "pointer",
              fontSize: "14px",
            }}
          >
            Try Again
          </button>
        </div>
      </React.Fragment>
    )}
    onReset={() => {
      Sentry.showReportDialog();
    }}
    onError={() => {
      Sentry.showReportDialog();
    }}
  >
    <QueryClientProvider client={queryClient}>
      <ErrorBoundary>
        <>
          <App />
        </>
      </ErrorBoundary>
    </QueryClientProvider>
  </Sentry.ErrorBoundary>
);

reportWebVitals();
