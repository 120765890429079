import { createTheme } from "@mui/material/styles";
import { deDE, enUS, trTR, arSA } from "@mui/material/locale";
const language: any = {
  de: deDE,
  en: enUS,
  tr: trTR,
  ar: arSA,
};
declare module "@mui/material/styles" {
  interface Theme {
    status: {
      success: object;
      warning: object;
    };
    customColor: {
      secondary: object;
    };
  }
  interface ThemeOptions {
    status?: {
      success?: object;
      warning?: object;
    };
    customColor: {
      secondary: object;
    };
  }
}

const Theme = (lang: any) => {
  return createTheme(
    {
      status: {
        success: {
          color: "rgba(84, 214, 44, 0.16)",
          textContrast: "rgb(34, 154, 22)",
        },
        warning: {
          color: "rgba(255, 193, 7, 0.16)",
          textContrast: "rgb(183, 129, 3)",
        },
      },
      customColor: {
        secondary: {
          color: "rgba(0, 0, 0, 0.53)",
        },
      },
      palette: {
        primary: {
          main: "rgba(2, 5, 211, 1);",
          dark: "rgba(0, 3, 139, 1);",
          light: "#0003cd36",
          // dark: "#520A76",
          // main: "#520A76;",
        },
        secondary: {
          light: "#fffadd",
          main: "#f5d312",
          dark: "#f5d312",
          // contrastText: "rgb(2, 136, 209)",
        },
        success: {
          main: "rgb(34, 154, 22)",
          light: "rgba(84, 214, 44, 0.16)",
          dark: "#1b5e20",
          //   contrastText: "#f5d312;",
        },
        warning: {
          main: "rgb(183, 129, 3)",
          light: "rgba(255, 193, 7, 0.16)",
          dark: "#e65100",
          // contrastText: "rgb(183, 129, 3)"
        },
        error: {
          main: "rgb(183, 33, 54)",
          light: "rgba(255, 72, 66, 0.16)",
          dark: "#c62828",
          // contrastText: "rgb(183, 33, 54)",
        },
        info: {
          main: "rgb(2, 136, 209)",
          light: "rgb(2, 136, 209, 0.16)",
          dark: "#01579b",
          // contrastText: "rgb(2, 136, 209)",
        },
      },
      typography: {
        fontFamily: '"Inter", sans-serif',
      },
    },
    language[lang]
  );
};

export default Theme;
