import http from "./http.service";
import Promisable from "./promisable.service";
import { getAppDispatch } from "utils/dispatch.util";
import { notificationHistoryActions } from "redux/slices/notificationHistory";

const url = "/admin/notificationHistory";

const NotificationHistoryService = {
  addNotificationHistory: async (data: any) => {
    const dispatch = getAppDispatch();
    dispatch?.(notificationHistoryActions.setNotificationHistory(data));
    dispatch?.(notificationHistoryActions.addNotificationHistory(data));
  },

  markAsRead: async (data: any) => {
    const dispatch = getAppDispatch();
    dispatch?.(notificationHistoryActions.setLoading(true));

    http.setJWT();

    const [success, error]: any = await Promisable.asPromise(
      http.post(`${url}/markAsRead`, data)
    );

    if (success) {
      const { notificationHistory } = success.data.data;
      if (data?.action == "delete" && data?.id) {
        dispatch?.(
          notificationHistoryActions.deleteNotificationHistory(data.id)
        );
      } else if (notificationHistory) {
        dispatch?.(
          notificationHistoryActions.updateNotificationHistory({
            id: notificationHistory._id,
            notificationHistory: notificationHistory,
          })
        );
      }
    }

    dispatch?.(notificationHistoryActions.setLoading(false));
    return [success, error];
  },

  bulkUpdate: async (data: any) => {
    const dispatch = getAppDispatch();
    dispatch?.(notificationHistoryActions.setLoading(true));

    http.setJWT();

    const [success, error]: any = await Promisable.asPromise(
      http.post(`${url}/bulkUpdate`, data)
    );

    if (success) {
      const { notificationHistories } = success.data.data;
      dispatch?.(notificationHistoryActions.refresh());
    }

    dispatch?.(notificationHistoryActions.setLoading(false));
    return [success, error];
  },

  getAllNotificationHistories: async (
    data: any,
    type: "main" | "default" = "main"
  ) => {
    const dispatch = getAppDispatch();
    dispatch?.(notificationHistoryActions.setLoading(true));

    http.setJWT();

    const [success, error]: any = await Promisable.asPromise(
      http.post(`${url}/getAll`, data)
    );

    if (success) {
      const { notificationHistories, totalCount, defaultReadCount } =
        success.data.data;
      dispatch?.(
        notificationHistoryActions.setNotificationHistoryHistories({
          notificationHistories,
          count: totalCount,
          type,
          defaultCount: defaultReadCount,
        })
      );
    }

    dispatch?.(notificationHistoryActions.setLoading(false));
    return [success, error];
  },
};

export default NotificationHistoryService;
