import {
  LeadingActions,
  SwipeableList,
  SwipeableListItem,
  SwipeAction,
  TrailingActions,
} from "react-swipeable-list";
import "react-swipeable-list/dist/styles.css";

import { useTranslation } from "react-i18next";
import { FaTrash, FaEnvelopeOpen } from "react-icons/fa";
import NotificationHistoryService from "services/notificationHistory.service";

const NotificationItem = ({ children, notification }: any) => {
  const { t } = useTranslation();

  const leadingActions = () => (
    <LeadingActions>
      <SwipeAction
        onClick={() => {
          NotificationHistoryService.markAsRead({
            id: notification._id,
            action: "delete",
          });
        }}
      >
        <div
          style={{
            backgroundColor: "red",

            maxHeight: "95%",
            display: "flex",
            justifyContent: "center",

            alignItems: "center",
            color: "white",
          }}
        >
          <FaTrash />
        </div>
      </SwipeAction>
    </LeadingActions>
  );

  const trailingActions = () => (
    <TrailingActions>
      <SwipeAction
        onClick={() => {
          if (!notification?.isRead) {
            NotificationHistoryService.markAsRead({
              id: notification._id,
              action: "read",
            });
          }
        }}
      >
        <div
          style={{
            backgroundColor: "blue",

            display: "flex",
            justifyContent: "center",
            maxHeight: "95%",

            alignItems: "center",
            color: "white",
          }}
        >
          <FaEnvelopeOpen />
        </div>
      </SwipeAction>
    </TrailingActions>
  );

  return (
    <>
      <SwipeableList className="w-full">
        <SwipeableListItem
          className="w-full"
          //   className=""
          leadingActions={leadingActions()}
          trailingActions={trailingActions()}
        >
          {children}
        </SwipeableListItem>
      </SwipeableList>
    </>
  );
};

export default NotificationItem;
