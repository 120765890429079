import type React from "react";
import {
  Snackbar,
  IconButton,
  Typography,
  Box,
  Avatar,
  styled,
  useTheme,
} from "@mui/material";
import {
  Close as CloseIcon,
  NotificationsActive as NotificationsActiveIcon,
} from "@mui/icons-material";
import { formatDistanceToNow } from "date-fns";
import { SiHandshakeProtocol } from "react-icons/si";
import { useAppDispatch, useAppSelector } from "redux/hooks";
import { notificationHistoryActions } from "redux/slices/notificationHistory";
import NotificationHistoryService from "services/notificationHistory.service";
import { useNavigate } from "react-router-dom";

const StyledSnackbar = styled(Snackbar)(({ theme }) => ({
  "& .MuiSnackbarContent-root": {
    backgroundColor: theme.palette.background.paper,
    color: theme.palette.text.primary,
    boxShadow: theme.shadows[4],
    borderRadius: theme.shape.borderRadius,
    minWidth: 344,
    maxWidth: 500,
  },
}));

const NotificationContent = styled(Box)(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  padding: theme.spacing(1, 2),
}));

const NotificationIcon = styled(Avatar)(({ theme }) => ({
  backgroundColor: theme.palette.primary.main,
  marginRight: theme.spacing(2),
}));

const NotificationText = styled(Box)({
  flex: 1,
});

const icons: Record<string, React.ReactNode> = {
  protocol: <SiHandshakeProtocol />,
};

const NotificationSnackbar: React.FC = () => {
  const theme = useTheme();
  const { notificationHistory } = useAppSelector(
    (state) => state.notificationHistory
  );

  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const handleClose = () => {
    dispatch?.(notificationHistoryActions.setNotificationHistory(null));
  };
  const navigateNotification = () => {
    const notification = notificationHistory;
    if (notification.type === "protocol") {
      navigate("/protocol-details/" + notification.typeId);
    } else if (notification.type === "task") {
      navigate("/task-details/" + notification.typeId);
    }
    if (notification.type === "message") {
      navigate("/chat?chatId=" + notification.typeId);
    }

    handleClose();
  };
  if (!notificationHistory) return null;
  return (
    <div>
      <StyledSnackbar
        anchorOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        open={!!notificationHistory}
        sx={{
          backgroundColor: theme.palette.background.paper,
          color: theme.palette.text.primary,
          boxShadow: theme.shadows[4],
          borderRadius: theme.shape.borderRadius,
          minWidth: 344,
          maxWidth: 500,
        }}
        autoHideDuration={5000}
        onClose={handleClose}
      >
        {notificationHistory && (
          <NotificationContent
            sx={{
              justifyContent: "space-between",
              width: "100%",
            }}
          >
            <NotificationIcon>
              {icons[notificationHistory?.type as keyof typeof icons] || (
                <NotificationsActiveIcon />
              )}
            </NotificationIcon>
            <NotificationText onClick={navigateNotification}>
              <Typography variant="subtitle1" component="div" fontWeight="bold">
                {notificationHistory?.title}
              </Typography>
              <Typography variant="body2" color="text.secondary">
                {notificationHistory?.description}
              </Typography>
              {/* <Typography
                variant="caption"
                color="text.secondary"
                sx={{ fontSize: 12 }}
              >
                {formatDistanceToNow(new Date(notificationHistory?.createdAt), {
                  addSuffix: true,
                })}
              </Typography> */}
            </NotificationText>
            <IconButton
              size="small"
              aria-label="close"
              color="inherit"
              onClick={handleClose}
            >
              <CloseIcon fontSize="small" />
            </IconButton>
          </NotificationContent>
        )}
      </StyledSnackbar>
    </div>
  );
};

export default NotificationSnackbar;
