import { LocationState } from ".";
import { createSlice, PayloadAction } from "@reduxjs/toolkit";

const initialState: LocationState = {
  drivers: [],
  vehicles: [],
  loading: false,
};

export const locationSlice = createSlice({
  name: "location",
  initialState,
  reducers: {
    setLoading: (state, action: PayloadAction<boolean>) => {
      state.loading = action.payload;
    },
    setEmptyDrivers: (state, action: PayloadAction<any>) => {
      state.drivers = [];
    },
    setDriverLocation: (state, action) => {
      const driversToUpdate = action.payload;

      driversToUpdate.forEach((driverData: any) => {
        const { driverId, ...rest } = driverData;
        const driverIndex = state.drivers.findIndex(
          (item) => item.driverId === driverId
        );
        if (driverIndex !== -1) {
          state.drivers[driverIndex] = {
            ...state.drivers[driverIndex],
            ...rest,
          };
        } else {
          state.drivers.push({ driverId, ...rest });
        }
      });
    },
    setVehicleLocation: (state, action) => {
      const vehiclesToUpdate = action.payload;
      vehiclesToUpdate.forEach((vehicleData: any) => {
        const { vehicleId, ...rest } = vehicleData;
        const vehicleIndex = vehicleId
          ? state.vehicles.findIndex((item) => item.vehicleId === vehicleId)
          : -1;
        if (vehicleIndex !== -1) {
          state.vehicles[vehicleIndex] = {
            ...state.vehicles[vehicleIndex],
            ...rest,
          };
        } else {
          state.vehicles.push({ vehicleId, ...rest });
        }
      });
    },
  },
});

const locationReducer = locationSlice.reducer;

export const locationActions = locationSlice.actions;
export default locationReducer;
