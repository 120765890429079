import { ChatState } from ".";
// import { config } from "config";

import { createSlice, PayloadAction } from "@reduxjs/toolkit";
// const default_page_size = config.PAGE_SIZE;

const initialState: ChatState = {
  chats: [],
  users: [],
  messages: [],
  chat: null,
  loading: true,
  messageFilter: { page: 1, page_size: 10 },
  messageCount: 0,
};

export const chatSlice = createSlice({
  name: "chat",
  initialState,
  reducers: {
    setLoading: (state, action: PayloadAction<boolean>) => {
      state.loading = action.payload;
    },
    addChat: (state, action) => {
      state.chats.unshift(action.payload);
    },
    setMessages: (state, action) => {
      state.messages = action.payload;
    },
    appendMessages: (state, action) => {
      state.messages = [...action.payload, ...state.messages]; // Append new messages at the top
    },
    setMessagesCount: (state, action) => {
      state.messageCount = action.payload;
    },

    addMessage: (state, action) => {
      const message = action.payload;
      const chatIndex = state.chats.findIndex(
        (c) => c._id === message.receiver
      );
      if (chatIndex !== -1) {
        state.chats[chatIndex].lastMessage = message;
      } else {
        const chatIndex = state.chats.findIndex(
          (c) => c._id === message.sendBy?._id
        );
        if (chatIndex !== -1) {
          state.chats[chatIndex].lastMessage = message;
        }
      }
    },
    setChat: (state, action) => {
      state.chat = action.payload;
    },
    updateChatUser: (state, action) => {
      const user = action.payload;
      console.log("user", user);
      const chatIndex = state.chats.findIndex((c) => c._id === user._id);
      console.log("chatIndex", chatIndex);

      if (chatIndex !== -1) {
        state.chats[chatIndex] = { ...state.chats[chatIndex], ...user };
      }
    },
    setChats: (state, action) => {
      state.chats = action.payload;
    },
    setMessageFilter: (state, action) => {
      state.messageFilter = action.payload;
    },
  },
});

const chatReducer = chatSlice.reducer;

export const chatActions = chatSlice.actions;
export default chatReducer;
