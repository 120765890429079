import * as React from "react";
import { useTheme } from "@mui/material/styles";
import Box from "@mui/material/Box";
import List from "@mui/material/List";
import { MdPersonPinCircle } from "react-icons/md";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemText from "@mui/material/ListItemText";
import CampaignIcon from "@mui/icons-material/Campaign";
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";
import { FaBuilding } from "react-icons/fa6";
import { useAppSelector } from "redux/hooks";
import BasicMenu from "components/atoms/BasicMenu";
import AuthService from "services/auth.service";
import { SiHandshakeProtocol } from "react-icons/si";
import { IoIosNotifications } from "react-icons/io";
import { FaCarAlt, FaUsers } from "react-icons/fa";
import { FaUserTie } from "react-icons/fa6";
import { FaFileInvoiceDollar } from "react-icons/fa";
import { FaUber } from "react-icons/fa6";
import { GiExpense } from "react-icons/gi";
import { MdOutlineRepartition } from "react-icons/md";
import Logo from "assets/images/long_logo.png";
import { Drawer, IconButton, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import moment from "moment";
import OneSignalService from "services/onesignal.service";
import { ImProfile } from "react-icons/im";
import { IoLogOutSharp } from "react-icons/io5";
import SocketService from "services/socket.service";
import Notification from "components/templates/NotificationCard";
import { RiNotificationBadgeLine } from "react-icons/ri";
import useMediaQuery from "@mui/material/useMediaQuery";
import {
  MdKeyboardDoubleArrowLeft,
  MdKeyboardDoubleArrowRight,
} from "react-icons/md";
import Avatar from "react-avatar";
import { config as configs } from "config";
import Localization from "./Localization";
import Header from "components/Header/Header";
import { MenuIcon } from "lucide-react";
import * as Sentry from "@sentry/react";
import { BsChatRightDots } from "react-icons/bs";

export const userMenu = (user: any) => {
  const list = [
    {
      title: "documentShift",
      link: "/shift",
      icon: <CampaignIcon />,
    },
    {
      title: "protocol",
      link: "/protocol",
      icon: <SiHandshakeProtocol />,
    },
    {
      title: "orders",
      link: "/orders",
      icon: <FaUber />,
    },
  ];
  if (["admin"].includes(user.role) || user.company?.displayRevenue) {
    list.push({
      link: "/revenues",
      title: "Revenues",
      icon: <FaFileInvoiceDollar />,
    });
  }
  if (user.company?.addExpense || user.role === "admin") {
    list.push({
      link: "/expenses",
      title: "Expenses",
      icon: <GiExpense />,
    });
  }
  if (user.company?.displayChat || user.role === "admin") {
    list.push({
      link: "/chat",
      title: "Chat",
      icon: <BsChatRightDots />,
    });
  }

  if (["driver"].includes(user.role)) {
    list.push({
      link: "/notification-history",
      title: "Notification History",
      icon: <RiNotificationBadgeLine />,
    });
  }

  // list.push({
  //   link: "/task",
  //   title: "Tasks",
  //   icon: <FaUserTie />,
  // });
  // list.push({
  //   link: "/chat",
  //   title: "Chat",
  //   icon: <BsChatRightDots />,
  // });
  return list;
};

const adminMenu = (role: string) => {
  return [
    ...(role === "admin"
      ? [
          {
            link: "/locations",
            text: "locations",
            active: "locations",
            icon: <MdPersonPinCircle size={25} />,
          },
          {
            link: "/users",
            text: "Users",
            active: "user",
            icon: <FaUsers />,
          },
          {
            link: "/statements",
            text: "Statements",
            active: "statements",
            icon: <FaFileInvoiceDollar />,
          },
        ]
      : []),
    {
      link: "/shifts",
      text: "shifts",
      active: "/shifts",
      icon: <CampaignIcon />,
    },
    {
      link: "/drivers",
      text: "drivers",
      active: "driver",
      icon: <FaUserTie />,
    },
    {
      link: "/vehicles",
      text: "vehicles",
      active: "vehicle",
      icon: <FaCarAlt />,
    },
    {
      link: "/protocols",
      text: "protocols",
      active: "protocols",
      icon: <SiHandshakeProtocol />,
    },
    {
      link: "/notifications",
      text: "Notifications",
      active: "notifications",
      icon: <IoIosNotifications />,
    },
    {
      link: "/own-company?tab=0",
      text: "ownCompany",
      active: "own",
      icon: <FaBuilding />,
    },
    {
      link: "/partners",
      text: "Partners",
      active: "partner",
      icon: <MdOutlineRepartition />,
    },
    ...(role === "admin"
      ? [
          {
            link: "/companies",
            title: "Companies",
            active: "company",
            icon: <FaBuilding />,
          },
        ]
      : []),
  ];
};
const drawerWidth = 300;
const collapsedDrawerWidth = 80;

const RightHeader = ({
  isCollapsed,
  setIsCollapsed,
  theme,
  open,
  setOpen,
  matches,
}: {
  isCollapsed: boolean;
  setIsCollapsed: (isCollapsed: boolean) => void;
  theme: any;
  open: boolean;
  setOpen: (open: boolean) => void;
  matches: boolean;
}) => {
  const { t } = useTranslation();
  const user = useAppSelector((state) => state.auth.user);
  const navigate = useNavigate();

  return (
    <Box
      sx={{
        display: "flex",
        px: 3,
        backgroundColor: "white",
        alignItems: "center",
        justifyContent: "space-between",
        gap: 2,
        height: "70px",
        borderBottom: "1px solid rgba(0,0,0,0.05)",
        position: "sticky",
        top: 0,
        zIndex: 10,
        backdropFilter: "blur(8px)",
      }}
    >
      {!matches && (
        <IconButton
          onClick={() => setOpen(!open)}
          sx={{
            display: { xs: "flex", lg: "none" },
          }}
        >
          <MenuIcon />
        </IconButton>
      )}
      <IconButton
        onClick={() => setIsCollapsed(!isCollapsed)}
        sx={{
          ml: isCollapsed ? 0 : "0",
          transition: "all 0.2s ease",
          display: { xs: "none", lg: "flex" },
          "&:hover": {
            backgroundColor: theme.palette.primary.main,
            color: "white",
          },
        }}
      >
        {isCollapsed ? (
          <MdKeyboardDoubleArrowRight />
        ) : (
          <MdKeyboardDoubleArrowLeft />
        )}
      </IconButton>

      <Header />
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          gap: 2,
          ml: "auto",
        }}
      >
        <Notification />
        <Localization />
        <BasicMenu
          variant="outlined"
          sx={{
            padding: "4px",
            borderRadius: "50%",
            "&:hover": { color: "white" },
            "@media (max-width: 600px)": {
              width: "40px",
              height: "40px",
            },
          }}
          list={[
            {
              text: t("Profile"),
              onClick: () => navigate("/profile"),
              icon: <ImProfile />,
            },
            {
              text: t("Logout"),
              onClick: () => AuthService.logout(),
              icon: <IoLogOutSharp />,
            },
          ]}
        >
          <Avatar
            size="40"
            round
            color="#0205d3"
            src={user.profileImage ? configs.API_URL + user.profileImage : ""}
            name={user.firstName + " " + user?.lastName}
          />
        </BasicMenu>
      </Box>
    </Box>
  );
};

export default function MiniDrawer({
  children,
}: {
  children: React.ReactNode;
}) {
  const theme = useTheme();
  const once = React.useRef(false);
  const matches = useMediaQuery("(min-width:1200px)");

  const { t, i18n } = useTranslation();
  const [searchParams] = useSearchParams();
  const userQuery = searchParams.get("user");
  const [open, setOpen] = React.useState(false);
  const [isCollapsed, setIsCollapsed] = React.useState(false);

  const navigate = useNavigate();
  let { pathname } = useLocation();
  if (userQuery && userQuery === "true") pathname = "/user";
  const user = useAppSelector((state) => state.auth.user);

  React.useEffect(() => {
    // if (user?.language) {
    //   i18n.changeLanguage(user.language);
    //   moment.locale(i18n.language);
    // }
    moment.locale(i18n.language);
    if (!once.current && user) {
      Sentry.setUser({
        id: user._id,
        email: user.email,
        firstName: user.firstName,
        lastName: user.lastName || "",
        company: user.company?.fullName || "",
        companyId: user.company?._id || "",
        role: user.role,
      });
      OneSignalService.connect(user);
      SocketService.connect(user);

      once.current = true;
    }
  }, [user, i18n]);

  const menuItemStyles = {
    listItem: {
      display: "block",
      mb: 1,
      mx: 1.5,
      borderRadius: "8px",
      overflow: "hidden",
      transition: "all 0.2s ease",
      "&:hover": {
        transform: "translateY(-1px)",
      },
    },
    button: (isActive: boolean) => ({
      minHeight: 48,
      justifyContent: isCollapsed ? "center" : "flex-start",
      px: 2.5,
      borderRadius: "8px",
      background: isActive ? theme.palette.primary.main : "white",
      boxShadow: isActive
        ? "0 4px 12px rgba(0,0,0,0.1)"
        : "0 2px 6px rgba(0,0,0,0.02)",
      color: isActive ? "white" : "black",
      transition: "all 0.2s ease",
      "&:hover": {
        background: isActive ? theme.palette.primary.dark : "rgba(0,0,0,0.02)",
        transform: "translateY(-1px)",
      },
    }),
    icon: {
      width: "40px",
      marginTop: "6px",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      transition: "transform 0.2s ease",
      transform: isCollapsed ? "scale(1.2)" : "scale(1)",
    },
  };

  if (!user || pathname === "/") return <>{children}</>;
  return (
    <Box sx={{ display: "flex", height: "100vh", bgcolor: "#f5f5f5" }}>
      {/* Left Sidebar */}
      <Drawer
        open={matches ? true : open}
        onClose={() => setOpen(false)}
        variant={matches ? "permanent" : "temporary"}
        sx={{
          width: isCollapsed ? collapsedDrawerWidth : drawerWidth,
          "& .MuiDrawer-paper": {
            width: isCollapsed ? collapsedDrawerWidth : drawerWidth,
            boxSizing: "border-box",
            border: "none",
            transition: "all 0.3s ease",
            backgroundColor: "white",
            borderRight: "1px solid rgba(0,0,0,0.05)",
            padding: "8px",
            ...(matches && {
              zIndex: 10,
            }),
          },
        }}
      >
        <Box
          sx={{
            p: 2,
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            borderBottom: "1px solid rgba(0,0,0,0.05)",
            mb: 2,
          }}
        >
          <img
            src={Logo}
            style={{
              height: "30px",
              objectFit: "contain",
              transition: "opacity 0.2s ease",
            }}
            alt="Logo"
          />
          {!matches && (
            <IconButton
              onClick={() => setOpen(!open)}
              sx={{ display: { xs: "flex", lg: "none" } }}
            >
              <MenuIcon />
            </IconButton>
          )}
        </Box>

        <Box sx={{ overflow: "auto", flex: 1 }}>
          {!isCollapsed && (
            <Typography
              variant="subtitle2"
              sx={{
                px: 3,
                mb: 1,
                color: "text.secondary",
                textTransform: "uppercase",
                letterSpacing: "0.5px",
                fontSize: "0.75rem",
              }}
            >
              {t("Driver Menu")}
            </Typography>
          )}

          <List>
            {userMenu(user)?.map((item: any, index: number) => (
              <ListItem key={index} disablePadding sx={menuItemStyles.listItem}>
                <ListItemButton
                  sx={menuItemStyles.button(pathname === item.link)}
                  onClick={() => {
                    navigate(item.link);
                    setOpen(false);
                  }}
                >
                  <div style={menuItemStyles.icon}>{item.icon}</div>
                  {!isCollapsed && (
                    <ListItemText
                      primary={t(item.title)}
                      primaryTypographyProps={{
                        fontSize: "0.875rem",
                        fontWeight: pathname === item.link ? 600 : 400,
                      }}
                    />
                  )}
                </ListItemButton>
              </ListItem>
            ))}
          </List>

          {["company", "admin"].includes(user?.role) && (
            <>
              {!isCollapsed && (
                <Typography
                  variant="subtitle2"
                  sx={{
                    px: 3,
                    mb: 1,
                    color: "text.secondary",
                    textTransform: "uppercase",
                    letterSpacing: "0.5px",
                    fontSize: "0.75rem",
                  }}
                >
                  {t("administration")}
                </Typography>
              )}
              <List>
                {adminMenu(user?.role)?.map((item: any, index: number) => (
                  <ListItem
                    key={index}
                    disablePadding
                    sx={menuItemStyles.listItem}
                  >
                    <ListItemButton
                      sx={menuItemStyles.button(pathname === item.link)}
                      onClick={() => {
                        navigate(item.link);
                        setOpen(false);
                      }}
                    >
                      <div style={menuItemStyles.icon}>{item.icon}</div>
                      {!isCollapsed && (
                        <ListItemText
                          primary={t(item.title || item.text)}
                          primaryTypographyProps={{
                            fontSize: "0.875rem",
                            fontWeight: pathname === item.link ? 600 : 400,
                          }}
                        />
                      )}
                    </ListItemButton>
                  </ListItem>
                ))}
              </List>
            </>
          )}
        </Box>
      </Drawer>

      <Box
        component="main"
        sx={{
          flex: 1,
          display: "flex",
          flexDirection: "column",
          width: `calc(100% - ${
            !matches ? "0" : isCollapsed ? collapsedDrawerWidth : drawerWidth
          }px)`,
          height: "100vh",
          transition: "width 0.3s ease",
          backgroundColor: "#f5f5f5",
        }}
      >
        <RightHeader
          isCollapsed={isCollapsed}
          setIsCollapsed={setIsCollapsed}
          theme={theme}
          open={open}
          setOpen={setOpen}
          matches={matches}
        />
        <Box
          sx={{
            p: 3,
            pt: 1,
            flex: 1,
            overflowY: "auto",
            "&::-webkit-scrollbar": {
              width: "8px",
            },
            "&::-webkit-scrollbar-thumb": {
              backgroundColor: "rgba(0,0,0,0.1)",
              borderRadius: "4px",
            },
          }}
        >
          {children}
        </Box>
      </Box>
    </Box>
  );
}
