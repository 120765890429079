export { default, modalActions, modalSlice } from "./modalSlice";

export enum MODAL {
  DISABLE_DRIVER = "DISABLE_DRIVER",
  INVITE_DRIVER = "INVITE_DRIVER",
  CONFIRMATION_FORM = "CONFIRMATION_FORM",
  CONFIRMATION_MODAL = "CONFIRMATION_MODAL",
  EXPORT_TABLE_OPTIONS = "EXPORT_TABLE_OPTIONS",
  FINISH_CAR_MOVEMENT = "FINISH_CAR_MOVEMENT",
  UPDATE_SHIFT = "UPDATE_SHIFT",
  ADD_PARTNER = "ADD_PARTNER",
  OTP_FORM = "OTP_FORM",
  PUSH_NOTIFICATION = "PUSH_NOTIFICATION",
  REVENUE_DETAILS = "REVENUE_DETAILS",
  ADD_EXPENSE = "ADD_EXPENSE",
  UPLOAD_REVENUE = "UPLOAD_REVENUE",
  REVENUE_DATE = "REVENUE_DATE",
  SEND_EMAIL = "SEND_EMAIL",
  ATTACH_DRIVER_REVENUE = "ATTACH_DRIVER_REVENUE",
  KICK_OFF_DRIVER = "KICK_OFF_DRIVER",
  UBER_STATUS_ALERT_DURATION = "UBER_STATUS_ALERT_DURATION",
  VEHICLE_HISTORY_MODAL = "VEHICLE_HISTORY_MODAL",
  ADD_VEHICLE_HISTORY_MODAL = "ADD_VEHICLE_HISTORY_MODAL",
  CHOOSE_PHOTO_MODAL = "CHOOSE_PHOTO_MODAL",
  CHOOSE_PROTOCOL_MODAL = "CHOOSE_PROTOCOL_MODAL",
  UPDATE_TASK_STATUS_MODAL = "UPDATE_TASK_STATUS_MODAL",
}

export type ModalType = "" | keyof typeof MODAL;

export interface OpenModalState {
  data: any;
  width: any;
  type: ModalType;
  loading?: boolean;
  drawer?: boolean;
  title?: string;
}

export type ModalState = OpenModalState & {
  open: boolean;
};
