import { useQuery } from "@tanstack/react-query";
import { config as configs } from "config";
const config = configs.SIMPLE_LOCALIZE;

export const useGetLanguages = () => {
  return useQuery<any, Error, { key: string; name: string }[]>({
    queryKey: ["Languages"],
    queryFn: async () => {
      try {
        const response = await fetch(`${config.apiBaseUrl!!}/v1/languages`, {
          method: "GET",
          headers: {
            "X-SimpleLocalize-Token": config.apiKey!!,
            "Content-Type": "application/json",
          },
        });
        const data = await response.json();
        return data.data;
      } catch (error) {
        console.error(error);
        return [];
      }
    },
    // Keep the data fresh for 24 hours
    staleTime: 24 * 60 * 60 * 1000,
    // Don't refetch on window focus
    refetchOnWindowFocus: false,
  });
};
