import http from "./http.service";
import Promisable from "./promisable.service";
import { getAppDispatch } from "utils/dispatch.util";
import { chatActions } from "redux/slices/chat";
import { messageActions } from "redux/slices/message";

const url = "/admin/chat";

const ChatService = {
  updateMessage: async (data: any) => {
    const dispatch = getAppDispatch();
    dispatch?.(messageActions.updateMessage(data));
  },
  updateChatUser: async (data: any) => {
    const dispatch = getAppDispatch();
    dispatch?.(chatActions.updateChatUser(data));
  },
  getChats: async () => {
    const dispatch = getAppDispatch();
    dispatch?.(chatActions.setLoading(true));

    http.setJWT();

    const [success, error]: any = await Promisable.asPromise(
      http.get(`${url}`)
    );

    if (success) {
      const { chats } = success.data.data;
      dispatch?.(chatActions.setChats(chats));
    }

    dispatch?.(chatActions.setLoading(false));
    return [success, error];
  },
  getChatByUser: async (id: string, messageFilter: any) => {
    const dispatch = getAppDispatch();
    dispatch?.(messageActions.setLoading(true));

    http.setJWT();

    const [success, error]: any = await Promisable.asPromise(
      http.get(`${url}/${id}`, { params: messageFilter })
    );

    if (success) {
      const { chat, messages } = success.data.data?.chat || {};
      dispatch?.(chatActions.setChat(chat));
      dispatch?.(messageActions.setChat(chat));

      if (messageFilter.page === 1) {
        dispatch?.(messageActions.setMessages(messages));
      } else {
        dispatch?.(messageActions.appendMessages(messages));
      }
      dispatch?.(messageActions.setCount(success.data.data.count || 0));
    }

    dispatch?.(messageActions.setLoading(false));
    return [success, error];
  },
  addMessage: (data: any) => {
    const dispatch = getAppDispatch();
    dispatch?.(chatActions.addMessage(data));
    dispatch?.(messageActions.addMessage(data));
    dispatch?.(messageActions.setMessage(data));
  },
};

export default ChatService;
