import { useTranslation } from "react-i18next";
import { useLocation } from "react-router-dom";

export default function Header() {
  const { pathname } = useLocation();
  const { t } = useTranslation();

  const getPageTitle = () => {
    const path = pathname.split("/")[1];
    return t(path);
  };
  return (
    <header>
      <h1
        style={{
          textTransform: "capitalize",
        }}
      >
        {getPageTitle()}
      </h1>
    </header>
  );
}
