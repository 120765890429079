import i18n from "i18next";
import Backend from "i18next-http-backend";
import LanguageDetector from "i18next-browser-languagedetector";
import { initReactI18next } from "react-i18next";
import axios from "axios";
import { config as configs } from "config";
const config = configs.SIMPLE_LOCALIZE;

const SYNC_INTERVAL = 30 * 1000; // 30 seconds
const isProductionCode = process.env.NODE_ENV === "production";
const fallbackLanguage = "de";
const projectToken = config.projectToken;
const apiKey = config.apiKey;
const apiBaseUrl = config.apiBaseUrl;
const cdnBaseUrl = config.cdnBaseUrl;
const environment = config.environment;

const loadPath = `${cdnBaseUrl}/${projectToken}/${environment}/{{lng}}`;
const loadPathWithNamespaces = `${cdnBaseUrl}/${projectToken}/${environment}/{{lng}}/{{ns}}`;
const configuration = {
  headers: {
    "X-SimpleLocalize-Token": apiKey,
  },
};

const createTranslationKeys = async (requestBody: any) => {
  try {
    const slAxios = axios.create();
    slAxios.defaults.baseURL = "";
    delete slAxios.defaults.headers.common["Authorization"];

    await slAxios.post(
      `${apiBaseUrl}/v1/translation-keys/bulk`,
      requestBody,
      configuration
    );
  } catch (error) {
    console.log("error", error);
  }
};
const updateTranslations = async (requestBody: any) => {
  try {
    const slAxios = axios.create();
    slAxios.defaults.baseURL = "";
    delete slAxios.defaults.headers.common["Authorization"];

    await slAxios.patch(
      `${apiBaseUrl}/v2/translations/bulk`,
      requestBody,
      configuration
    );
  } catch (error) {
    console.log(error);
  }
};

const missing: any = [];
const saveMissing = async () => {
  try {
    if (missing.length === 0 || isProductionCode) {
      return;
    }
    console.info(`Saving ${missing.length} missing translation keys`);

    const translationKeys = missing.map((element: any) => ({
      key: element.translationKey,
      namespace: element.namespace,
    }));

    await createTranslationKeys({ translationKeys });

    const translations = missing.map((element: any) => ({
      key: element.translationKey,
      namespace: element.namespace,
      language: "en",
      text: element.fallbackValue,
    }));
    await updateTranslations({ translations });
    missing.length = 0;
  } catch (error) {
    console.error(`Error during saving missing translation keys: ${error}`);
  }
};

// You can control how often a missing translation keys are sent to SimpleLocalize.
// Lowering this value may lead to hitting rate-limiter, 30 seconds is a sweet spot.
setInterval(async () => {
  await saveMissing();
}, SYNC_INTERVAL);

i18n
  .use(Backend)
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    fallbackLng: fallbackLanguage,

    detection: {
      order: ["localStorage", "cookie", "navigator"],
      lookupLocalStorage: "i18nextLng",
      caches: ["localStorage", "cookie"],
      cookieMinutes: 10080, // 7 days
    },

    backend: {
      loadPath: loadPathWithNamespaces, // or loadPathWithNamespaces if you use namespaces
      // loadPathWithNamespaces: loadPathWithNamespaces,
      // crossDomain: true,
    },

    saveMissing: !isProductionCode, // save missing keys only in development mode
    defaultNS: "translation", // you can set default namespace here
    missingKeyHandler: async (
      languages,
      namespace,
      translationKey,
      fallbackValue
    ) => {
      console.debug(
        `[${namespace}][${translationKey}] not available in Translation Hosting`
      );
      missing.push({
        translationKey: translationKey,
        namespace: namespace ?? "translation",
        language: languages[0] ?? fallbackLanguage,
        fallbackValue: fallbackValue ?? "",
      });
    },
  });
export default i18n;
