import { lazy } from "react";
import { MODAL } from "redux/slices/modal";

const DisableModal = lazy(() => import("./DisableModal"));
const InviteDriverModal = lazy(() => import("./InviteDriverModal"));
const ConfirmationForm = lazy(() => import("./ConfirmationForm"));
const ConfirmationModal = lazy(() => import("./ConfirmationModal"));
const ExportTableOptions = lazy(() => import("./ExportTableOptions"));

const CarMovementFinish = lazy(() => import("./CarMovementFinish"));
const UpdateShift = lazy(() => import("./UpdateShift/UpdateShift"));
const AddPartner = lazy(() => import("./AddPartner"));
const OTPForm = lazy(() => import("./OTPForm"));
const SendPushNotification = lazy(() => import("./SendPushNotification"));
const RevenueDetails = lazy(() => import("./RevenueDetails"));
const AddExpense = lazy(() => import("./AddExpense"));
const UploadRevenue = lazy(() => import("./UploadRevenue"));
const RevenueDates = lazy(() => import("./RevenueDates"));
const SendEmail = lazy(() => import("./SendEmail"));
const AttachDriverRevenueModal = lazy(
  () => import("./AttachDriverRevenueModal")
);
const KickOffDriverModal = lazy(() => import("./KickOffUserModal"));
const UberAlertNotificationModal = lazy(
  () => import("./UberAlertNotificationModal")
);
const VehicleHistoryModal = lazy(() => import("./VehicleHistoryModal"));
const AddVehicleHistoryModal = lazy(() => import("./AddVehicleHistoryModal"));
const ChoosePhotoModal = lazy(() => import("./ChoosePhotoModal"));
const ChooseProtocolModal = lazy(() => import("./ChooseProtocolModal"));
const UpdateTaskStatusModal = lazy(() => import("./UpdateTaskStatusModal"));

export { default } from "./AppModal";

export type ModalMapper = {
  [key in MODAL]: "" | JSX.Element;
};

export const modalMapper: ModalMapper = {
  DISABLE_DRIVER: <DisableModal />,
  INVITE_DRIVER: <InviteDriverModal />,
  CONFIRMATION_FORM: <ConfirmationForm />,
  CONFIRMATION_MODAL: <ConfirmationModal />,
  EXPORT_TABLE_OPTIONS: <ExportTableOptions />,
  FINISH_CAR_MOVEMENT: <CarMovementFinish />,
  UPDATE_SHIFT: <UpdateShift />,
  ADD_PARTNER: <AddPartner />,
  OTP_FORM: <OTPForm />,
  PUSH_NOTIFICATION: <SendPushNotification />,
  REVENUE_DETAILS: <RevenueDetails />,
  ADD_EXPENSE: <AddExpense />,
  UPLOAD_REVENUE: <UploadRevenue />,
  REVENUE_DATE: <RevenueDates />,
  SEND_EMAIL: <SendEmail />,
  ATTACH_DRIVER_REVENUE: <AttachDriverRevenueModal />,
  KICK_OFF_DRIVER: <KickOffDriverModal />,
  UBER_STATUS_ALERT_DURATION: <UberAlertNotificationModal />,
  VEHICLE_HISTORY_MODAL: <VehicleHistoryModal />,
  ADD_VEHICLE_HISTORY_MODAL: <AddVehicleHistoryModal />,
  CHOOSE_PHOTO_MODAL: <ChoosePhotoModal />,
  CHOOSE_PROTOCOL_MODAL: <ChooseProtocolModal />,
  UPDATE_TASK_STATUS_MODAL: <UpdateTaskStatusModal />,
};
