import { TaskTemplateState } from ".";
import { SelectOption } from "components/atoms/Select";
import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { config } from "config";

const default_page_size = config.PAGE_SIZE;
const initialState: TaskTemplateState = {
  count: 0,
  refresh: 0,
  templates: [],
  template: null,
  loading: false,
  templateOptions: [],
  current_filters: {},
  sorting: {},
  refreshLoader: false,
  filters: {
    page: 1,
    page_size: default_page_size,
  },
};

export const taskTemplateSlice = createSlice({
  name: "taskTemplate",
  initialState,
  reducers: {
    clear: (state) => {
      state.templateOptions = [];
      state.templates = [];
    },
    setLoading: (state, action: PayloadAction<boolean>) => {
      state.loading = action.payload;
    },
    addTaskTemplate: (state, action) => {
      state.templates.unshift(action.payload);
    },
    updateTaskTemplate: (state, action) => {
      const { id, template } = action.payload;
      state.templates.every(({ _id }, i) => {
        if (id === _id) {
          state.templates[i] = template;
          return false;
        }
        return true;
      });
    },
    setTaskTemplate: (state, action) => {
      state.template = action.payload;
    },
    setTaskTemplates: (
      state,
      action: PayloadAction<{ templates: any[]; count: number }>
    ) => {
      const { count, templates } = action.payload;
      let options: SelectOption[] = [];

      templates?.forEach(({ _id, firstName, lastName }: any) => {
        const option = { value: _id, label: `${firstName} ${lastName || ""}` };
        options.push(option);
      });

      state.count = count;
      state.templates = templates;
      state.refreshLoader = false;
      // state.driverOptions = options;
    },

    setPage: (state, action: PayloadAction<number>) => {
      state.refresh += 1;
      state.refreshLoader = true;
      state.filters.page = action.payload;
    },
    refresh: (state) => {
      state.refresh += 1;
      state.refreshLoader = true;
    },
    resetPage: (state) => {
      state.filters.page = 1;
    },

    setFilters: (state, action) => {
      state.filters = action.payload;
      state.refreshLoader = true;
    },
    setSorting: (state, action) => {
      state.sorting = action.payload;
      state.refreshLoader = true;
    },
    setCurrentFilters: (state, action) => {
      state.current_filters = action.payload;
    },
    resetFilters: (state) => {
      state.refresh += 1;
      state.refreshLoader = true;
      state.filters = initialState.filters;
      state.current_filters = initialState.current_filters;
      state.sorting = initialState.sorting;
    },
    deleteTaskTemplate: (state, action) => {
      const id = action.payload;
      state.templates.every(({ _id }, i) => {
        if (id === _id) {
          state.count -= 1;
          state.templates.splice(i, 1);
          return false;
        }
        return true;
      });
    },
  },
});

const taskTemplateReducer = taskTemplateSlice.reducer;
export const taskTemplateActions = taskTemplateSlice.actions;
export default taskTemplateReducer;
