import { combineReducers } from "redux";
import { reducer as formReducer } from "redux-form";

import authReducer from "./slices/auth";
import userReducer from "./slices/user";
import modalReducer from "./slices/modal";
import modelReducer from "./slices/model";
import driverReducer from "./slices/driver";
import loaderReducer from "./slices/loader";
import companyReducer from "./slices/company";
import vehicleReducer from "./slices/vehicle";
import formLoaderReducer from "./slices/formLoader";
import timeZoneReducer from "./slices/timeZone";
import carMovementReducer from "./slices/carMovement";
import shiftReducer from "./slices/shift";
import protocolReducer from "./slices/protocol";
import partnerReducer from "./slices/partner";
import notificationReducer from "./slices/notification";
import uberOrderReducer from "./slices/uberOrder";
import revenueReducer from "./slices/revenue";
import expenseReducer from "./slices/expense";
import locationReducer from "./slices/location";
import taskReducer from "./slices/task";
import tagReducer from "./slices/tag";
import uberStatusReducer from "./slices/uberstatus";
import notificationHistoryReducer from "./slices/notificationHistory";
import taskTemplateReducer from "./slices/taskTemplate";
import chatReducer from "./slices/chat";
import messageReducer from "./slices/message";

const appReducer = combineReducers({
  form: formReducer,
  auth: authReducer,
  user: userReducer,
  modal: modalReducer,
  model: modelReducer,
  driver: driverReducer,
  loader: loaderReducer,
  company: companyReducer,
  vehicle: vehicleReducer,
  formLoader: formLoaderReducer,
  timeZone: timeZoneReducer,
  carMovement: carMovementReducer,
  shift: shiftReducer,
  task: taskReducer,
  taskTemplate: taskTemplateReducer,
  tag: tagReducer,
  protocol: protocolReducer,
  partner: partnerReducer,
  notification: notificationReducer,
  uberOrder: uberOrderReducer,
  revenue: revenueReducer,
  expense: expenseReducer,
  location: locationReducer,
  uberStatus: uberStatusReducer,
  notificationHistory: notificationHistoryReducer,
  chat: chatReducer,
  message: messageReducer,
});

const rootReducer = (state: any, action: any) => {
  if (action.type === "auth/logout") state = {};
  return appReducer(state, action);
};

export default rootReducer;
